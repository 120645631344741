import { Store } from 'pullstate'

import { NAV_MOBILE_TRESHOLD, SIDEBAR_MOBILE_TRESHOLD
} from '../Components/Documentation/API_configs'

const DocsStore = new Store(
{
	version: 'v1',
	API_KEY: 'mw_test_123...example...vHj',
	API_TOKEN: 'Bearer ad7s5ez8eze5d...example...7d8s5ddsd78sdd',
	host: document.location.origin.replace('http://', 'https://'),

	sidebar: window.innerWidth < SIDEBAR_MOBILE_TRESHOLD
	? false
	: window.sessionStorage.getItem('docs@sidebar') !== 'closed',

	navbar: window.innerWidth < NAV_MOBILE_TRESHOLD
	? false
	: window.sessionStorage.getItem('docs@navbar') !== 'closed'
});

export default DocsStore;