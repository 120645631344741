/** @type { Object.<string, number> } **/
export const TIMES =
{
	'1 second': 1000,
	'1 minute': 1000 * 60,
	'1 hour': 1000 * 60 * 60,
	'1 day': 1000 * 60 * 60 * 24,
	'1 week': 1000 * 60 * 60 * 24 * 7,
	'1 month': 1000 * 60 * 60 * 24 * 30,
	'1 year': 1000 * 60 * 60 * 24 * 265
};

/**
 * @description Retourne si oui ou non une valeur est le bool true ou
 * un string contenant true ('true').
 * Une valeur truthy mais différente comme {}, 'T' ou 42 retournera false.
 *
 * @param { any } value
 * @returns { boolean } is true or 'true'
**/
export function isTrue (value)
{
	if (value === true || value === 'true') return true;

	return ('' + value) === 'true';
}

/**
 * @description Retourne si oui ou non une valeur est le bool false ou
 * un string contenant false ('false').
 * Une valeur truthy mais différente comme {}, 'T' ou 42 retournera false.
 *
 * @param { any } value
 * @returns { boolean } is false or 'false'
**/
export function isFalse (value)
{
	if (value === false || value === 'false') return false;

	return ('' + value) === 'false';
}

/**
 * @description Retourne un string avec la première lettre en majuscule.
 * Si la valeur est vide ou n'est pas un string, renvoie la valeur initiale.
 *
 * @param { string } str
 * @returns
**/
export function capitalize (str)
{
	if (!str || typeof str !== 'string') return str;

	return str.slice(0, 1).toUpperCase() + str.slice(1);
}

/**
 * Retourne le nom du fichier d'un chemin donné. Supporte les formats windows,
 * unix et les url ou uri.
 *
 * @param { string } path chemin
 *
 * @returns { string } nom du fichier au bout du chemin
**/
export function filenameFromPath (path='')
{
	const unix_path = path.split('\\').join('/');

	if (unix_path.endsWith('/')) return '';

	return unix_path.includes('/')
	? unix_path.slice(unix_path.lastIndexOf('/')+1)
	: unix_path;
}

/**
 * @description retourne une promesse résolue après le nombre de ms passé en
 * paramètres. Utilise setTimeout.
 *
 * @param { number } [timeout=1000]
 *
 * @returns { Promise } promesse résolue via setTimeout
**/
export function someTime (timeout=1000)
{
	return new Promise(function (resolve, reject)
	{
		setTimeout(resolve, timeout);
	});
}