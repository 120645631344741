import { useCallback } from 'react'

import { TbArrowBarLeft } from 'react-icons/tb'

import DocsStore from '../../Stores/Docs'

import ApiTopLogo from './ApiTopLogo'
import ApiNavbar  from './ApiNavbar'

export default function ApiHeader (props)
{
	const with_collapse = props['with-sidebar-collapse'];

	const sidebar = DocsStore.useState(s => s.sidebar === true);

	const collapseSidebar = useCallback(function (e)
	{
		DocsStore.update(s => { s.sidebar = !s.sidebar; });
	},
	[]);

	const collapse_label = !with_collapse ? undefined
	: sidebar
	? 'Fermer la navigation'
	: 'Ouvrir la navigation';

	const side_bar_collapse = with_collapse ?
	(
		<button type="button" className="c-api-page__sidebar-collapse"
			onClick={ collapseSidebar } aria-label={ collapse_label }
			aria-controls="docs-sidebar" data-actived={ !sidebar }>
			<TbArrowBarLeft className="c-api-page__sidebar-collapse-icon" />

			<span className="c-api-page__sidebar-collapse-label">
				{ collapse_label }
			</span>

		</button>
	)
	: undefined;

	return (
		<header className={ 'c-api-page__header' +
			(with_collapse ? ' has-sidebar-toggle' : '') }>
			{
				props.logo ?
				(
					<div className="c-api-page__top-logo-container">
						<ApiTopLogo />
					</div>
				)
				: undefined
			}
			{ side_bar_collapse }
			<div className="c-api-page__header-content">
				<ApiNavbar />
			</div>
		</header>
	);
}