/* eslint-disable react/jsx-max-depth */
import { useEffect } from 'react'

import { Outlet, useLoaderData } from 'react-router-dom'

import ApiHeader from '../Documentation/ApiHeader'

import request from '../Functions/ajax/request'

import RolesStore from '../../Stores/Roles'

import setRolesStore from '../../StoresFunctions/setRolesStore'

import SuspendedLayout from '../../SuspendedLayout'

export default function SimpleDocsLayout ()
{
	const { page } = useLoaderData();

	const roles_date = RolesStore.useState(s => s.date);

	useEffect(() =>
	{
		const controller = new AbortController();

		(async function fetchRoles ()
		{
			if (roles_date) return;

			try
			{
				var { roles, user_id } = await request('/auth/get-roles')
				.config({ controller })
				.post();
			}
			catch (update)
			{
				return;
			}

			setRolesStore({ roles, user_id });
		})();

		return () => controller?.abort?.();
	},
	[roles_date]);

	return (
		<SuspendedLayout resolve={ page }>
			
			<div className="docs-page">

				<div className="c-api-page c-api-page--simple">

					<div className="c-api-page__flex">

						<article className="c-api-page__right">

							<ApiHeader logo />

							<main id="js-scrolling-element"
								className="c-api-page__content">
								<div className="c-api-page__padded-content">
									<Outlet />
								</div>
							</main>

						</article>

					</div>

				</div>

			</div>
			
		</SuspendedLayout>
	);
}