import { useState, useEffect, useRef, useCallback } from 'react'

import ApiSidebarTitle from './ApiSidebarTitle'

import isInViewport from '../../Functions/isInViewport'

function getScrollingElement ()
{
	return document
	.getElementById('js-scrolling-element') || document.scrollingElement;
}

function getNodes (links=[])
{
	return links.map(function (hash, i)
	{
		return [document.getElementById(hash.slice(1))?.parentElement, i];
	});
}

export default function ApiSidebarMenu ({ ...props })
{
	const [active, setActive] = useState();

	const _ref = useRef();
	const _ref_links = useRef();
	const _ref_nodes = useRef();

	useEffect(function ()
	{
		if (!_ref) return cleanUp;

		let ticking = false;

		_ref_links.current = Array.from(_ref.current.querySelectorAll('[href*="#'))
		.map(node => node.getAttribute('href'));

		getScrollingElement().addEventListener('scroll', handleScroll);

		return cleanUp;

		function handleScroll (e)
		{
			if (ticking) return;
			ticking = true;

			window.requestAnimationFrame(function ()
			{
				ticking = false;

				if (!_ref_nodes.current)
				{
					_ref_nodes.current = getNodes(_ref_links.current);
				}

				const match = _ref_nodes.current
				.find(([node, i]) => isInViewport(node, 210));

				if (match)
				{
					const [node, index] = match;

					const hash = _ref_links.current[index];

					if (hash)
					{
						setActive(hash);
						history.replaceState({ }, '', hash);
					}
				}
			});
		}

		function cleanUp ()
		{
			getScrollingElement().removeEventListener('scroll', handleScroll);
		}
	},
	[]);

	return (
		<nav className="c-api-sidebar" ref={ _ref }>

			<div className="c-api-sidebar__part">

				<ApiSidebarTitle href="#s-intro" active-hash={ active }>
					Introduction
				</ApiSidebarTitle>

				<ApiSidebarTitle href="#s-auth" active-hash={ active }>
					Authentification
				</ApiSidebarTitle>

				<ApiSidebarTitle href="#s-uptime" active-hash={ active }>
					API uptime
				</ApiSidebarTitle>

				<ApiSidebarTitle href="#s-et-obj" active-hash={ active }>
					Établissement
				</ApiSidebarTitle>

				<ApiSidebarTitle href="#s-et-simple-obj" active-hash={ active }
					sub={ 1 }>
					Simplifié
				</ApiSidebarTitle>

				<ApiSidebarTitle href="#s-et-list" active-hash={ active }
					sub={ 1 }>
					Liste
				</ApiSidebarTitle>

				<ApiSidebarTitle href="#s-et-details" active-hash={ active }
					sub={ 1 }>
					Détail
				</ApiSidebarTitle>

				<ApiSidebarTitle href="#s-sp-obj" active-hash={ active }>
					Espace
				</ApiSidebarTitle>

				<ApiSidebarTitle href="#s-sp-simple-obj" active-hash={ active }
					sub={ 1 }>
					Simplifié
				</ApiSidebarTitle>

				<ApiSidebarTitle href="#s-sp-list" active-hash={ active }
					sub={ 1 }>
					Liste
				</ApiSidebarTitle>

				<ApiSidebarTitle href="#s-sp-details" active-hash={ active }
					sub={ 1 }>
					Détail
				</ApiSidebarTitle>

				<ApiSidebarTitle href="#s-sp-availability" active-hash={ active }
					sub={ 1 }>
					Disponibilité
				</ApiSidebarTitle>

				<ApiSidebarTitle href="#s-sp-prices" active-hash={ active }
					sub={ 1 }>
					Tarifs
				</ApiSidebarTitle>

				<ApiSidebarTitle href="#s-book-new" active-hash={ active }>
					Réservation
				</ApiSidebarTitle>

				<ApiSidebarTitle href="#s-book-cancel" active-hash={ active }>
					Annulation
				</ApiSidebarTitle>

				<ApiSidebarTitle href="#s-register" active-hash={ active }>
					Inscription
				</ApiSidebarTitle>

				<ApiSidebarTitle href="#s-img-obj" active-hash={ active }>
					Image
				</ApiSidebarTitle>
			</div>

		</nav>
	);
}