import { useEffect } from 'react'

import { Outlet, useLoaderData } from 'react-router-dom'

import AdminUserMenu from '../Navigation/AdminUserMenu'
import AdminHeader   from '../Navigation/AdminHeader'

import request from '../Functions/ajax/request'
import bemit   from '../Functions/bemit'

import RolesStore from '../../Stores/Roles'

import setRolesStore from '../../StoresFunctions/setRolesStore'

import SuspendedLayout from '../../SuspendedLayout'

export default function BackLayout ()
{
	const { page } = useLoaderData();

	const roles_status = RolesStore.useState(s => s.status);

	useEffect(() =>
	{
		if (roles_status !== 'pending') return;

		const controller = new AbortController();

		(async function fetchRoles ()
		{
			try
			{
				var { roles, user_id } = await request('/auth/get-roles')
				.config({ controller })
				.post();
			}
			catch (update)
			{
				return;
			}

			setRolesStore({ roles, user_id });
		})();

		return () => controller?.abort?.();
	},
	[roles_status]);

	return (
		<SuspendedLayout resolve={ page }>

			<div className="back-page">
				<div className={ bemit('o-header', ['admin']) }>
					<AdminHeader />
					<AdminUserMenu />
				</div>

				<Outlet />
			</div>

		</SuspendedLayout>
	);
}