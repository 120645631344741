import { useState, useEffect, useMemo, useCallback } from 'react'

import { NavLink, useLocation } from 'react-router-dom'

import { IoHomeOutline } from 'react-icons/io5'
import { HiMenuAlt2 }    from 'react-icons/hi'

import bemit from '../Functions/bemit'

import HeaderItemToggle from './HeaderItemToggle'

import PagesStore from '../../Stores/Pages'
import RolesStore from '../../Stores/Roles'

const default_config =
{
	welcome:
	{
		url: '/admin/accueil',
		label: 'Accueil',
		exact: true,
		Icon: IoHomeOutline,
		hide_label: true
	},

	users:
	{
		url: '/admin/liste-des-utilisateurs',
		label: 'Utilisateurs'
	}
};

function getLinks ({ ...params })
{
	const { roles=[], pages: state_pages=[], pathname } = params;

	const config = roles.includes('admin')
	? default_config
	: default_config;

	const doc_pathname = pathname || document.location.pathname;

	let pages = Array.isArray(state_pages)
	? state_pages
	: [];

	return Object.keys(config || { }).reduce(function (new_links, name)
	{
		const infos = { ...config[name] };

		if (infos.url && !pages.some(path => path === infos.url))
		{
			return new_links;
		}

		const sub_items = Object.keys(infos.sub_items || { })
		.filter(name => pages.some(path => path === infos.sub_items[name].url));

		if (infos.sub_items && !sub_items.length) return new_links;

		if (sub_items.length)
		{
			infos.sub_items = sub_items.reduce(function (sub_items, sub_name)
			{
				sub_items[sub_name] = { ...infos.sub_items[sub_name] };

				return sub_items;
			},
			{ });
		}

		if (infos.url === doc_pathname)
		{
			infos.active = true;
		}
		else if (infos.exact !== true && doc_pathname.startsWith(infos.url + '/'))
		{
			infos.active = true;
		}

		const active_item = Object.keys(infos.sub_items || { }).find(function (name)
		{
			return (doc_pathname + '/')
			.startsWith(infos.sub_items[name].url + '/');
		});

		if (active_item)
		{
			infos.active = true;

			infos.sub_items =
			{
				...infos.sub_items,

				[active_item]:
				{
					...infos.sub_items[active_item],

					active: true
				}
			};
		}

		return { ...new_links, [name]: infos };
	},
	{ });
}

export default function AdminHeader (props)
{
	const roles = RolesStore.useState(store => store.roles);
	const pages = PagesStore.useState(store => store.pages);

	const location = useLocation();

	const [open_dropdown, setOpenDropdown] = useState();
	const [links, setLinks] = useState(getLinks({ pages, roles }));
	const [open, setOpen] = useState(false);

	useEffect(() =>
	{
		setLinks(getLinks({ pages, roles, pathname: location.pathname }));
	},
	[pages, location.pathname, roles]);

	const toggle = useCallback(function (e)
	{
		setOpen(state => !state);
	},
	[]);

	const items = useMemo(function ()
	{
		return Object.keys(links || { }).map(function (name)
		{
			const infos = { ...links[name] };

			let modifiers = infos.active === true ? ['active'] : [];

			if (infos.sub_items)
			{
				modifiers.push('dropdown');

				if (open_dropdown === name) modifiers.push('open');
			}

			const sub_items = Object.keys(infos.sub_items || { })
			.map(function (name)
			{
				const item = infos.sub_items[name];

				let class_name = 'c-menu-item__subitem';

				if (item.active === true) class_name += ' active';

				const Icon = item.Icon || undefined;

				if (Icon) class_name += ' has-icon';

				let output =
				(
					<li className={ class_name } key={ item.url }>

						<a className="c-menu-item__subitem-link"
							href={ item.url }>

							{
								Icon
								? <Icon className="c-menu-item__icon" />
								: undefined
							}

							{
								item.hide_label ?
								(
									<span className="u-readers-only">
										{ item.label }
									</span>
								)
								: item.label
							}

						</a>

					</li>
				);

				return output;
			});

			const label = infos.hide_label === true
			? <span className="u-readers-only">{ infos.label }</span>
			: infos.label;

			const Icon = infos.Icon;

			const link_element = infos.sub_items ?
			(
				<HeaderItemToggle setOpened={ setOpenDropdown }
					open={ open_dropdown === name }
					name={ name } Icon={ infos.Icon }>
					{ label }
				</HeaderItemToggle>
			)
			:
			(
				<NavLink className="c-menu-item__link"
					to={ infos.url }>
					{
						Icon
						? <Icon className="c-menu-item__icon" />
						: undefined
					}
					{ label }
				</NavLink>
			)

			return (
				<li key={ infos.url || name }
					className={ bemit('c-menu-item', modifiers) }>

					{ link_element }

					{
						sub_items.length > 0 ?
						(
							<ul role="menu" className="c-menu-item__menu">
								{ sub_items }
							</ul>
						)
						: undefined
					}

				</li>
			);
		});
	},
	[links, open_dropdown]);

	return (
		<nav className={ 'o-header__navbar' + (open ? ' is-open' : '') }>

			<button type="button" className="o-header__toggle"
				onClick={ toggle }>
				<HiMenuAlt2 className="o-header__toggle-icon" />
				<span className="u-readers-only">
					Menu (mobile)
				</span>
			</button>

			<ul className="o-header__menu" aria-hidden={ open ? 'false' : 'true' }>
				{ items }
			</ul>

		</nav>
	);
}