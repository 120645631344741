export default function isInViewport (node, min=0)
{
	if (!node || typeof node.getBoundingClientRect !== 'function') return false;

	var dims = node.getBoundingClientRect();

	if (dims.left > window.innerWidth) return false; // à droite de l'écran
	if (dims.left < -dims.width) return false; // à gauche de l'écran

	if (dims.top > window.innerHeight) return false; // sous l'écran
	if (dims.top < -dims.height) return false; // au dessus de l'écran

	if (min > 0 === false) return true;

// le min visible est plus grand que la taille de l'élément
	if (dims.height < min) return false;

	const visible_px = dims.top < 0 // coupé en haut
	? dims.top + dims.height
	: dims.top < (window.innerHeight - dims.height) // entier
	? dims.height
	: window.innerHeight - dims.top;

	return visible_px > min;
}