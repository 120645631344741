import { Suspense } from 'react'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import SimpleDocsLayout from './Components/Layouts/SimpleDocsLayout'
import DocsLayout       from './Components/Layouts/DocsLayout'
import AuthLayout       from './Components/Layouts/AuthLayout'
import BackLayout       from './Components/Layouts/BackLayout'

import NotFoundPage from './Components/Errors/Pages/NotFoundPage'
import RouterError  from './Components/Errors/RouterError'

import LayoutLoader from './Components/Loaders/LayoutLoader'

import RootSpinner from './Components/Spinners/RootSpinner'

import AppVersion from './Components/Configs/AppVersion'

import ROUTES from './routes'

const routes =
[
	{
		path: '*',
		errorElement: RouterError,
		element: <NotFoundPage error="Cette page n'existe pas." center />
	},

	{
		path: '/',
		errorElement: RouterError,
		loader: LayoutLoader,
		element: <DocsLayout />,

		children:
		[
			{
				path: '',
				lazy: ROUTES.HomePage
			},
			{
				path: '/docs/:version',
				lazy: ROUTES.HomePage
			}
		]
	},

	{
		path: '/',
		errorElement: RouterError,
		loader: LayoutLoader,
		element: <SimpleDocsLayout />,

		children:
		[
			{
				path: '/docs/:version/gestion-des-erreurs',
				lazy: ROUTES.ErrorsDocsPage
			}
		]
	},

	{
		path: '/',
		errorElement: RouterError,
		loader: LayoutLoader,
		element: <AuthLayout />,

		children:
		[
			{
				path: 'login',
				lazy: ROUTES.LoginPage
			},
			{
				path: 'mot-de-passe-oublie',
				lazy: ROUTES.RequestPasswordPage
			},
			{
				path: 'mot-de-passe-oublie/nouveau-mot-de-passe/:token',
				lazy: ROUTES.NewPasswordPage
			}
		]
	},

	{
		path: 'mon-compte',
		errorElement: RouterError,
		loader: LayoutLoader,
		element: <BackLayout />,

		children:
		[
			{
				path: '',
				lazy: ROUTES.MyAccountPage
			}
		]
	},

	{
		path: '/admin',
		errorElement: RouterError,
		loader: LayoutLoader,
		element: <BackLayout />,

		children:
		[
			{
				path: 'accueil',
				lazy: ROUTES.AdminHomePage
			},
			{
				path: 'liste-des-utilisateurs',
				lazy: ROUTES.UsersListPage
			},
			{
				path: 'liste-des-utilisateurs/nouvel-utilisateur',
				lazy: ROUTES.NewUserPage
			},
			{
				path: 'liste-des-utilisateurs/:user_id',
				lazy: ROUTES.UserPage
			}
		]
	}
];

const router = createBrowserRouter(routes);

export default function App ()
{
	return (
		<>
			<Suspense fallback={ <RootSpinner active /> }>
				<RouterProvider router={ router } />
			</Suspense>

			<AppVersion />
		</>
	);
}