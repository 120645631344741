import { Link } from 'react-router-dom'

import DocsStore from '../../Stores/Docs'

export default function ApiTopLogo (props)
{
	const version = DocsStore.useState(s => s.version);

	return (
		<div className="c-api-page__top-logo">

			<Link to={ '/docs/' + version } className="c-api-page__top-home">
				<img src="/images/logo-mywo.svg" alt="logo myWo"
					className="c-api-page__logo"
				/>

				<div className="c-api-page__top-logo-txt">
					API
				</div>
			</Link>

		</div>
	);
}