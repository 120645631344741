import { useEffect, useCallback, useRef } from 'react'

import { Link } from 'react-router-dom'

import { HiMenuAlt1 } from 'react-icons/hi'
import { HiX }        from 'react-icons/hi'

import DocsStore from '../../Stores/Docs'

import nodeIsOrBelongsTo from '../Functions/nodeIsOrBelongsTo.js'

const links =
[
	{ to: '/docs/$version', label: 'Documentation' },
	{ to: '/docs/$version/gestion-des-erreurs', label: 'Gestion des erreurs' }
];

export default function ApiNavbar (props)
{
	const version = DocsStore.useState(s => s.version);
	const navbar  = DocsStore.useState(s => s.navbar);

	const _ref_toggle = useRef();

	useEffect(function ()
	{
		window.sessionStorage.setItem('docs@navbar', navbar ? 'open' : 'closed');
	},
	[navbar]);

	useEffect(function ()
	{
		if (!navbar) return cleanUp;

		document.addEventListener('click', handleClick);

		return cleanUp;

		function handleClick (e)
		{
			const update = !_ref_toggle?.current
			? true
			: !nodeIsOrBelongsTo(e.target, _ref_toggle.current);

			if (update)
			{
				DocsStore.update(s => { s.navbar = !s.navbar });
			}
		}

		function cleanUp ()
		{
			document.removeEventListener('click', handleClick);
		}
	},
	[navbar]);

	const toggleNav = useCallback(function (e)
	{
		DocsStore.update(function (store)
		{
			store.sidebar = false;
			store.navbar = !store.navbar;
		});
	},
	[]);

	const ToggleIcon = navbar ? HiX : HiMenuAlt1;

	const mobile_toggle =
	(
		<button ref={ _ref_toggle } type="button" className="c-api-navbar__toggle"
			onClick={ toggleNav } aria-labelledby="docs-navbar-toggle-label"
			aria-controls="docs-navbar" data-actived={ !navbar }>
			<ToggleIcon className="c-api-navbar__toggle-icon" />
			<span id="docs-navbar-toggle-label"
				className="c-api-navbar__toggle-label">
				{ navbar ? 'Fermer' : 'Menu' }
			</span>
		</button>
	);

	const path = document.location.pathname;

	const items = links.map(function (opt)
	{
		const to = opt.to.replace('$version', version);

		const is__active = path === to;

		const class_name = 'c-api-navbar__link' + (is__active ? ' active' : '');

		return (
			<li key={ to } className="c-api-navbar__item">
				<Link to={ to } className={ class_name }>
					{ opt.label }
				</Link>
			</li>
		);
	});

	return (
		<nav className="c-api-navbar">

			<div className="c-api-navbar__toggle-container">
				{ mobile_toggle }
			</div>

			<ul className="c-api-navbar__list" id="docs-navbar"
				aria-hidden={ navbar ? 'false' : 'true' }>
				{ items }
			</ul>

		</nav>
	);
}