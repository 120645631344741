import { memo, useCallback } from 'react'

import { BsArrowReturnRight } from 'react-icons/bs'

import DocsStore from '../../../Stores/Docs'

export default memo(function ApiSidebarTitle ({ ...props })
{
	const is__active = props['active-hash'] === props.href;

	const closeSidebar = useCallback(function (e)
	{
		if (window.innerWidth < 901 === false) return;

		DocsStore.update(s => { s.sidebar = false; });
	},
	[]);

	const sub_icon = props.sub > 0 ?
	(
		<span className="c-api-sidebar__sub-indicator">
			<BsArrowReturnRight className="c-api-sidebar__title-sub-icon" />
		</span>
	)
	: undefined;

	const wrapped = props.href ?
	(
		<a href={ props.href } data-sub={ String(props.sub || 0) }
			className={ 'c-api-sidebar__title' + (is__active ? ' is-active' : '') }
			onClick={ closeSidebar }>
			{ sub_icon }
			<span className="c-api-sidebar__link">
				{ props.children }
			</span>
		</a>
	)
	:
	(
		<h2 className={ 'c-api-sidebar__title c-api-sidebar__link' +
			(is__active ? ' is-active' : '') } data-sub={ String(props.sub || 0) }>
			{ sub_icon }
			{ props.children }
		</h2>
	);

	return wrapped;
});