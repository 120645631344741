export const site_name = 'myWO API';
export const logo = '/images/logo-mywo.svg';

export const colors =
{
	primary: '#1B3A5F',
	blue: '#1E5AC2'
};

export const js_version = '0.1.0a';

export const js_refresh_rate = { minutes: 30 }; // luxon Duration params

export const API_LINKS_HOST = 'https://mywo.fr';

export const API_ASSETS_HOST = 'https://mywo.fr';

export const ALLOWED_MIN_OVERFLOW = 15;

export const social_networks =
{
	facebook: 'https://www.facebook.com/Mywo.coworking',
	instagram: 'https://www.instagram.com/mywo_fr'
};

const config = { site_name, logo, colors, js_version, js_refresh_rate,
API_LINKS_HOST, ALLOWED_MIN_OVERFLOW, social_networks };

export default config;